import React, { useState, useEffect } from 'react';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import profilePicture from './profile.png';
import { Send, XCircle, Phone, MessageCircle, Mail } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const Links = () => {
  const [showModal, setShowModal] = useState(false);
  const [mounted, setMounted] = useState(false);
  
  useEffect(() => {
    setMounted(true);
  }, []);

  const links = [
    {
      title: 'Buy A TerpScoop 🛍️',
      url: 'https://shop.terpmetrix.com',
    },
    {
      title: 'Terpscoops Instagram 🥄',
      url: 'https://instagram.com/terpscoops',
    },
    {
      title: 'Terpmetrix Home 🏠',
      url: 'https://terpmetrix.com',
    },
    {
      title: 'TerpFocus 📸',
      url: 'https://terpfocus.com',
    },
    {
      title: 'TerpTracker (Alpha) 🔍',
      url: 'https://terptracker.co',
    },
  ];

  const socialLinks = [
    {
      icon: faInstagram,
      url: 'https://instagram.com/terpmetrix',
    },
    {
      icon: faTwitter,
      url: 'https://twitter.com/terpmetrix',
    },
    {
      icon: faLinkedin,
      url: 'https://www.linkedin.com/company/terpmetrix',
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: 'spring', stiffness: 300, damping: 24 }
    }
  };

  const socialVariants = {
    hover: { scale: 1.2, rotate: 5, transition: { duration: 0.2 } }
  };

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <motion.div 
            className="fixed inset-0 bg-slate-900/75 backdrop-blur-sm z-10 flex justify-center items-center p-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setShowModal(false)}
          >
            <motion.div 
              className="flex flex-col max-w-md w-full bg-white rounded-2xl shadow-2xl border-2 border-green-500 p-6 text-center text-slate-800"
              initial={{ scale: 0.9, y: 20, opacity: 0 }}
              animate={{ scale: 1, y: 0, opacity: 1 }}
              exit={{ scale: 0.9, y: 20, opacity: 0 }}
              transition={{ type: 'spring', damping: 25, stiffness: 300 }}
              onClick={(e) => e.stopPropagation()}
            >
              <button 
                onClick={() => setShowModal(false)} 
                className="self-end text-green-600 hover:text-green-800 transition-colors duration-200"
              >
                <XCircle size={24} />
              </button>
              <p className="text-2xl text-slate-800 font-bold mb-4">Sam Steingard</p>
              <div className="flex justify-center space-x-8 mt-2">
                <motion.a 
                  href="tel:3015254028" 
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  className="p-3 bg-green-50 rounded-full text-green-600 hover:bg-green-100 transition-colors duration-200"
                >
                  <Phone size={32}/>
                </motion.a>
                <motion.a 
                  href="sms:3015254028" 
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  className="p-3 bg-green-50 rounded-full text-green-600 hover:bg-green-100 transition-colors duration-200"
                >
                  <MessageCircle size={32} />
                </motion.a>
                <motion.a 
                  href="mailto:sam@terpmetrix.com" 
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  className="p-3 bg-green-50 rounded-full text-green-600 hover:bg-green-100 transition-colors duration-200"
                >
                  <Mail size={32} />
                </motion.a>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="min-h-screen bg-gradient-to-b from-slate-50 to-slate-100 text-slate-800 flex flex-col items-center px-4 py-8 md:justify-center">
        <motion.div 
          className="bg-white p-8 rounded-3xl shadow-xl w-full max-w-md mt-4 items-center border border-slate-200"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, ease: "easeOut" }}
        >
          <motion.div 
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className="relative mx-auto mb-6 w-32 h-32"
          >
            <div className="absolute inset-0 bg-gradient-to-tr from-green-500 to-emerald-400 rounded-full blur-xl opacity-20 animate-pulse"></div>
            <motion.img
              src={profilePicture}
              alt="Profile"
              className="relative w-32 h-32 mx-auto mb-4 rounded-full object-cover border-4 border-white shadow-lg"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            />
          </motion.div>

          <motion.h1 
            className="text-2xl md:text-3xl flex justify-center font-bold mb-3 text-slate-800"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.5 }}
          >
            Terpmetrix
          </motion.h1>

          <motion.div 
            className="flex justify-center mb-6"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            {socialLinks.map((link, index) => (
              <motion.a
                key={index}
                href={link.url}
                target="_blank"
                rel="noreferrer"
                className="text-2xl mx-3 text-green-600"
                variants={itemVariants}
                whileHover="hover"
                custom={socialVariants}
              >
                <motion.div variants={socialVariants}>
                  <FontAwesomeIcon icon={link.icon} />
                </motion.div>
              </motion.a>
            ))}
          </motion.div>

          <motion.button
            onClick={() => setShowModal(true)}
            className="rounded-full block w-2/3 py-3 mb-8 mx-auto text-center text-green-600 font-semibold border-2 border-green-500 hover:bg-green-50 transition-colors duration-300"
            whileHover={{ scale: 1.03, boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1)" }}
            whileTap={{ scale: 0.97 }}
          >
            Get In Touch
          </motion.button>

          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate={mounted ? "visible" : "hidden"}
            className="space-y-3"
          >
            {links.map((link, index) => (
              <motion.div
                key={index}
                variants={itemVariants}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <a href={link.url} target="_blank" rel="noopener noreferrer">
                  <button
                    className="rounded-full block w-full py-4 text-center text-white font-semibold bg-gradient-to-r from-green-600 to-emerald-500 hover:from-green-700 hover:to-emerald-600 shadow-md hover:shadow-lg transition-all duration-300"
                  >
                    {link.title}
                  </button>
                </a>
              </motion.div>
            ))}
          </motion.div>

          <motion.div 
            className="flex flex-col justify-center items-center w-full mt-10 mb-2"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.2, duration: 0.5 }}
          >
            <h2 className="text-lg font-bold italic text-slate-800 pb-4">Sign up for the newsletter!</h2>

            <form
              className="w-full justify-center items-center"
              name="mailing-list"
              method="POST"
            >
              <input type="hidden" name="form-name" value="mailing-list" />
              <div className="flex flex-row justify-center space-x-2 group">
                <label className="w-full">
                  <input 
                    name="email" 
                    type="email" 
                    placeholder="Email address" 
                    className="bg-white rounded-full border-2 border-green-500 h-12 w-full p-2 pl-4 focus:outline-none focus:ring-2 focus:ring-green-400 focus:border-transparent transition-all duration-300" 
                  />
                </label>
                <motion.button 
                  type="submit" 
                  className="rounded-full bg-green-600 hover:bg-green-700 h-12 w-12 flex items-center justify-center transition-colors duration-300"
                  whileHover={{ scale: 1.05, rotate: 5 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Send className="text-white" />
                </motion.button>
              </div>
            </form>
          </motion.div>
        </motion.div>
        
        <motion.p 
          className="text-sm text-slate-500 mt-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.5, duration: 0.5 }}
        >
          © {new Date().getFullYear()} Terpmetrix
        </motion.p>
      </div>
    </>
  );
};

export default Links;